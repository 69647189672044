import React, { useState } from 'react'
import InteractiveBottleCard from './InteractiveBottleCard'
import { FaPlusCircle } from 'react-icons/fa'
import expandImageDesktopNew from '../images/adheretech-expand-icon.svg'
import collapseImageDesktopNew from '../images/adheretech-collapse-icon.svg'
import bottleGlass from '../images/bottle_glass.png'

export default function InteractiveBottle() {
  const [isVisible, setIsVisible] = useState({ one: false, two: false, three: false })

  const expandImage = (isVisible) => (
    <img className={`${isVisible ? 'hide' : ''}`} src={expandImageDesktopNew} alt="expand" />
  )

  const collapseImage = (isVisible) => (
    <img className={`${isVisible ? '' : 'hide'}`} src={collapseImageDesktopNew} alt="collapse" />
  )

  const bottleImage = () => <img src={bottleGlass} className="bottle__image" alt="glass" />

  return (
    <div className="section section--blue">
      <div className="interactiveBottle">
        <header className="interactiveBottle__header">
          <span>Tap the</span>
          <FaPlusCircle className="plus-circle" size={32} />
          <span>to see just how the Aidia Smart Bottle takes the worry out of taking medicine.</span>
        </header>
        <div className="bottle">
          {bottleImage()}
          <div
            className="expand one"
            role="button"
            onClick={() => setIsVisible({ ...isVisible, one: !isVisible.one })}
            onKeyPress={() => setIsVisible({ ...isVisible, one: !isVisible.one })}
            tabIndex={0}
          >
            {expandImage(isVisible.one)}
            {collapseImage(isVisible.one)}
          </div>

          <InteractiveBottleCard
            headerText="Brilliant bottle"
            content="The smart bottle knows when the patient takes their medication and alerts them if they don’t."
            className="one"
            onClose={() => setIsVisible({ ...isVisible, one: !isVisible.one })}
            isVisible={isVisible.one}
          />

          <div
            className="expand two"
            role="button"
            onClick={() => setIsVisible({ ...isVisible, two: !isVisible.two })}
            onKeyPress={() => setIsVisible({ ...isVisible, two: !isVisible.two })}
            tabIndex={0}
          >
            {expandImage(isVisible.two)}
            {collapseImage(isVisible.two)}
          </div>

          <InteractiveBottleCard
            headerText="Music to their ears"
            content="Gentle musical chimes remind the patient that it’s dose time and continue periodically until the dose is taken."
            className="two"
            onClose={() => setIsVisible({ ...isVisible, two: !isVisible.two })}
            isVisible={isVisible.two}
          />

          <div
            className="expand three"
            role="button"
            onClick={() => setIsVisible({ ...isVisible, three: !isVisible.three })}
            onKeyPress={() => setIsVisible({ ...isVisible, three: !isVisible.three })}
            tabIndex={0}
          >
            {expandImage(isVisible.three)}
            {collapseImage(isVisible.three)}
          </div>

          <InteractiveBottleCard
            headerText="Glow, baby, glow"
            content="Glowing lights appear to alert the patient that it’s almost time for their scheduled dose."
            className="three"
            onClose={() => setIsVisible({ ...isVisible, three: !isVisible.three })}
            isVisible={isVisible.three}
          />
        </div>
      </div>
    </div>
  )
}
