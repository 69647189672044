import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/Hero'
import InteractiveBottle from '../components/InteractiveBottle'
import QuoteBox from '../components/QuoteBox'
import ActionBanner from '../components/ActionBanner'
import HowAidiaWorksBox from '../components/HowAidiaWorksBox'
import ReadyToGoCard from '../components/ReadyToGoCard'
import iconText from '../images/icon-text.svg'
import iconAlert from '../images/icon-alert.svg'
import iconSupport from '../images/icon-support.svg'
import iconDevices from '../images/icon-devices.png'
import logoTm from '../images/logo-tm.svg'
import Video from '../components/Video'

const howAidiaWorksPage = () => {
  return (
    <main className="how-aidia-works-page">
      <Hero heading="Adherence made effortless with Aidia" color="grayblue" image="clock-devices">
        <p>
          Living with a serious disease can be overwhelming. Despite best efforts, patients struggle to take their
          medication as intended: doses get missed, refills get delayed, or treatment stops entirely.
          <br />
          <br />
          Traditional approaches to helping patients start and stay on their medication don’t work because they add to
          the burden or act too late. Only Aidia makes establishing and maintaining new medication-taking behaviors
          effortless.
        </p>
      </Hero>

      <div className="page-section a-simple-system">
        <h2 className="medium-heading-1">Because every dose — and every day — counts</h2>
        <div className="page-section-content">
          <div className="aidia-bottle-image">
            <img src={logoTm} alt="aidia logo" width={200} />
          </div>
          <div className="page-section-text">
            <p>
              Those missed pills can quickly add up, reducing time on therapy and risking a patient's health.
              <br />
              <br />
              Aidia is the in-home partner every medication should have. An elegantly simple and proven system designed
              to gently remind patients to take their medication and intervene when they don’t — early enough to make a
              difference. Making sure not a day is lost on their path to better health.
            </p>
          </div>
        </div>
      </div>

      <div className="page-section how-it-works">
        <h3>How our revolutionary system works:</h3>
        <div className="how-it-works-grid">
          <HowAidiaWorksBox
            image={iconDevices}
            header="Helpful reminders"
            content={
              <p>Aidia smart devices light and chime to gently alert patients when it’s time for their next dose.</p>
            }
            className="helpful-reminders"
          />
          <HowAidiaWorksBox
            image={iconSupport}
            header="Friendly support"
            content={
              <p>
                Settings and dose schedule can easily be changed by texting or calling Aidia Support. Patient
                satisfaction is consistently ≥95%.
              </p>
            }
            className="friendly-support"
          />
          <HowAidiaWorksBox
            image={iconText}
            header="Real-time reinforcement"
            content={
              <p>
                Medication-taking behavior is made visible to the patient, reinforcing behaviors and forming a positive
                reward cycle. Personalized behavioral messaging via SMS & voice provide targeted intervention when it
                matters most.
              </p>
            }
            className="reassuring-backup"
          />
          <HowAidiaWorksBox
            image={iconAlert}
            header="Coordination with care teams"
            content={
              <p>
                If patient starts to lose their medication-taking behavior, Aidia gradually escalates interventions to
                help the patient get back on track, coordinating with care teams when it makes the most difference.
              </p>
            }
            className="coordination"
          />
        </div>
      </div>

      <InteractiveBottle />

      <div className="page-section">
        <h2>A patient-embraced medication partner</h2>
        <h2 className="sub-heading">Patient satisfaction consistently exceeds 95%</h2>
        <p className="final-p">
          Over 36,000 patients served across multiple disease states, including Oncology, Transplant, Mental Health, MS,
          HIV. Aidia provides patients with peace of mind, giving them one less thing to worry about.
          <br />
          <br />
          <strong>Net Promoter Score = 89%</strong> (ranked as world class by Google)
        </p>

        <div className="page-section-content image-with-quotes">
          <div>
            <StaticImage
              src="../images/simple-seamless-woman2.png"
              placeholder="none"
              alt="Simple and seamless"
              layout="constrained"
            />
          </div>
          <div>
            <QuoteBox
              text="Every day I am blessed to be here, so every day when I see that blue light on the bottle, it reminds me that I have something to live for."
              shadow={false}
            />
            <QuoteBox
              text="It serves as good reminder. It eliminates having to think about if I took my medication or not."
              shadow={false}
            />
          </div>
        </div>
      </div>

      <div className="page-section">
        <h2 className="medium-heading-2">Ready to go, right out of the box</h2>

        <div className="ready-to-go-grid">
          <ReadyToGoCard
            header="Zero Setup"
            text="Aidia devices arrive charged and pre-set to patient’s dose schedule."
          />
          <ReadyToGoCard
            header="Easy to Use"
            text="Patient takes medication as usual and Aidia does the rest — at no cost to the patient."
          />
          <ReadyToGoCard header="Confidential" text="Aidia is HIPAA-compliant and meets other regulatory standards." />
        </div>
      </div>

      <div className="page-section--blue behavioral-change">
        <div className="page-section-content">
          <h2>A proven behavioral change system</h2>
          <h2 className="sub-heading">
            Aidia partners with the patient so they can optimize their path to better health
          </h2>
          <p className="final-p">
            Aidia helps patients change their behavior to gradually align with their prescribed medication dosing
            schedule, resulting in more days benefitting from their medication and fewer gap days risking their path to
            better health.
          </p>
        </div>
        <div className="page-section-content">
          <StaticImage
            src="../images/retention-graph.png"
            alt="Behavioral Change"
            placeholder="none"
            layout="constrained"
          />
        </div>
      </div>

      <div className="page-section how-it-works-video">
        <div className="page-section-content">
          <h2>See how the Aidia System works</h2>
          <Video
            videoSrcURL="https://www.youtube.com/embed/wMrdrKWZ6oA"
            videoTitle="AdhereTech Smart Bottle System - How it works"
          />
        </div>
      </div>

      <ActionBanner
        header="Are you already an Aidia Smart Cap or Smart Bottle user?"
        content="Check out these frequently asked questions."
        linkPath="/faqs"
        linkText="View FAQs"
        color="white"
      />
    </main>
  )
}

export default howAidiaWorksPage
