import React from 'react'

export default function HowAidiaWorksBox({ image, header, content, className }) {
  return (
    <div className={`how-it-works-box ${className}`}>
      <div className="how-it-works-image-container">
        <img src={image} alt={header} />
      </div>
      <div className="how-it-works-text">
        <h3>{header}</h3>
        {content}
      </div>
    </div>
  )
}

HowAidiaWorksBox.defaultProps = {
  className: '',
}
