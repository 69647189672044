import React from 'react'
import { Link } from 'gatsby'

export default function ActionBanner({ header, content, linkPath, linkText, color = 'blue' }) {
  return (
    <div className={`action-banner action-banner-${color}`}>
      <div className="action-banner__content">
        {header && <h3>{header}</h3>}
        <p>{content}</p>
        <Link className="action-link" to={linkPath}>
          {linkText} &gt;
        </Link>
      </div>
    </div>
  )
}
